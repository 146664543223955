/* ========================================================================
 * DOM-based Routing
 * Based on http://goo.gl/EUTi53 by Paul Irish
 *
 * Only fires on body classes that match. If a body class contains a dash,
 * replace the dash with an underscore when adding it to the object below.
 *
 * .noConflict()
 * The routing is enclosed within an anonymous function so that you can
 * always reference jQuery with $, even when in .noConflict() mode.
 * ======================================================================== */

(function($) {

  // Use this variable to set up the common and page specific functions. If you
  // rename this variable, you will also need to rename the namespace below.
  var Sage = {
    // All pages
    'common': {
      init: function() {

        var qs = (function(a) {
            if (a === "") { return {}; }
            var b = {};
            for (var i = 0; i < a.length; ++i)
            {
                var p=a[i].split('=', 2);
                if (p.length === 1) {
                    b[p[0]] = "";
                }
                else {
                    b[p[0]] = decodeURIComponent(p[1].replace(/\+/g, " "));
                }
            }
            return b;
        })(window.location.search.substr(1).split('&'));

        if (window.Cookies != null) {
          var token = qs.aff_ref;
          var currentData = Cookies.getJSON('aff_ref');
          if (token && (currentData == null || currentData.token !== token)) {
            var data = {
              token: token,
              date: moment().format()
            };
            Cookies.set('aff_ref', data, { expires: 30, path: '/' });
          }

          // temp solution - rewrite email address if affiliate token exists
          if (Cookies.getJSON('aff_ref')) {
            jQuery('a[href="mailto:information@blueytravel.com"]').each(function(i, el) {
              jQuery(el).text('enquiry@blueytravel.com');
              jQuery(el).attr('href', 'mailto:enquiry@blueytravel.com');
            });
          }
        }

        if (window.gform != null) {
          // fix ridiculous defaults imposed by gravity forms for the datepicker
          gform.addFilter('gform_datepicker_options_pre_init', function(optionsObj, formId, fieldId){
            optionsObj = {
              yearRange: '-100:+20',
              showOn: 'focus',
              dateFormat: 'dd-mm-yy',
              changeMonth: false,
              changeYear: false,
              suppressDatePicker: false,
              onClose: null,
              beforeShow: null
            };

            var inputId = 'input_' + formId + '_' + fieldId;
            var element = jQuery('#' + inputId);

            if (element.hasClass('datepicker_with_icon')) {
              optionsObj.showOn = 'both';
              optionsObj.buttonImage = jQuery('#gforms_calendar_icon_' + inputId).val();
              optionsObj.buttonImageOnly = true;
            }

            return optionsObj;
          });
        }

        // hack for mobile browsers, so the iOS keyboard doesn't appear
        var isMobile = window.matchMedia("only screen and (max-width: 760px)");

        if (isMobile && isMobile.matches) {
          var content = $('header .social-buttons').contents();
          $('footer .social-buttons').append(content);
        }

        // JavaScript to be fired on all pages
        if (document.location.href.match(/\?replytocom/)) {
          $('body').addClass('comment-reply');
        }
        else {
          $('.comment-respond .comment-reply-title').on('click', function() {
            $('.comment-respond .comment-reply-title').hide();
            $('.comment-respond form').show();
          });
        }
      },
      finalize: function() {
        // JavaScript to be fired on all pages, after page specific JS is fired
      }
    },
    // Home page
    'home': {
      init: function() {
        // JavaScript to be fired on the home page
        var selectRegion = function() {
          $('dl.region-packages').hide();
          $('#' + $(this).val()).show();
        };
        $('#menu-choose-region').on('change', selectRegion);
        $('dl.region-packages:first').show();
      },
      finalize: function() {
        // JavaScript to be fired on the home page, after the init JS
      }
    },
    // About us page, note the change from about-us to about_us.
    'about_us': {
      init: function() {
        // JavaScript to be fired on the about us page
      }
    },
    'page_template_template_package_detail': {
      init: function() {
        // hack for mobile browsers, so the iOS keyboard doesn't appear
        var isMobile = window.matchMedia("only screen and (max-width: 760px)");

        if (isMobile && isMobile.matches) {
          $("#input_2_1").attr('readonly', 'readonly');
          $("#input_2_2").attr('readonly', 'readonly');
        }

        $("#input_2_1").on('change', function() {
          var duration = $('#holiday_duration_days').val();

          if (duration) {
            duration = parseInt(duration) - 1;
            var date = $('#input_2_1').val();
            if (date) {
              var newDate = moment(date, 'DD-MM-YYYY').add(duration, 'days');
              $("#input_2_2").datepicker('setDate', newDate.format('DD-MM-YYYY'));
            }
          }
        });

        if (document.location.hash === '#gf_2') {
          $(".package-availability-form").collapse('show');
        }

        $(".package-availability-form").on("shown.bs.collapse", function () {
          $('html, body').animate({
            scrollTop: $(".package-availability-form").offset().top
          }, 1000);
        });
      }
    },
    'page_template_template_campervan_detail': {
      init: function() {

        // hack for mobile browsers, so the iOS keyboard doesn't appear
        var isMobile = window.matchMedia("only screen and (max-width: 760px)");

        if (isMobile && isMobile.matches) {
          $("#input_3_5").attr('readonly', 'readonly');
          $("#input_3_6").attr('readonly', 'readonly');
        }

        if (document.location.hash === '#gf_3') {
          $(".campervan-enquiry-form").collapse('show');
        }

        $("#input_3_5").on('change', function() {
          var duration = $('#holiday_duration_days').val();

          if (duration) {
            duration = parseInt(duration) - 1;
            var date = $('#input_3_5').val();
            if (date) {
              var newDate = moment(date, 'DD-MM-YYYY').add(duration, 'days');
              $("#input_3_6").datepicker('setDate', newDate.format('DD-MM-YYYY'));
            }
          }
        });

        $(".campervan-enquiry-form").on("shown.bs.collapse", function () {
          $('html, body').animate({
            scrollTop: $(".campervan-enquiry-form").offset().top
          }, 1000);
        });
      }
    }
  };

  // The routing fires all common scripts, followed by the page specific scripts.
  // Add additional events for more control over timing e.g. a finalize event
  var UTIL = {
    fire: function(func, funcname, args) {
      var fire;
      var namespace = Sage;
      funcname = (funcname === undefined) ? 'init' : funcname;
      fire = func !== '';
      fire = fire && namespace[func];
      fire = fire && typeof namespace[func][funcname] === 'function';

      if (fire) {
        namespace[func][funcname](args);
      }
    },
    loadEvents: function() {
      // Fire common init JS
      UTIL.fire('common');

      // Fire page-specific init JS, and then finalize JS
      $.each(document.body.className.replace(/-/g, '_').split(/\s+/), function(i, classnm) {
        UTIL.fire(classnm);
        UTIL.fire(classnm, 'finalize');
      });

      // Fire common finalize JS
      UTIL.fire('common', 'finalize');
    }
  };

  // Load Events
  $(document).ready(UTIL.loadEvents);

})(jQuery); // Fully reference jQuery after this point.
